import React, { useState } from 'react';
import axios from 'axios';

const SendMessage = () => {
  const [to, setTo] = useState('');
  const [message, setMessage] = useState('');

  const handleSendMessage = async () => {
    try {
      const response = await axios.post('http://localhost:5000/send-message', { to, message });
      console.log('Message sent:', response.data);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div>
      <h2>Send WhatsApp Message</h2>
      <input
        type="text"
        placeholder="Recipient Phone Number"
        value={to}
        onChange={(e) => setTo(e.target.value)}
      />
      <textarea
        placeholder="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={handleSendMessage}>Send Message</button>
    </div>
  );
};

export default SendMessage;
